<template>
  <WeContainer card="">
    <!-- Filter -->
    <WeTableSearch
      class="mb-4"
      v-on:search="onSearch"
      v-bind:clear-enable="false"
      v-bind:showOnLoad="checkFilterStorage()"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Gender -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="gender"
                label="Cinsiyet"
                placeholder="Cinsiyet"
                v-bind:option-prop="genders"
                v-model="filter.gender"
              />
            </div>
            <!-- Gender -->

            <!-- Email -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="email"
                label="E-Posta Uzantısı"
                placeholder="Örnek: @algoritim.com"
                v-model="filter.email"
              />
            </div>
            <!-- Email -->

            <!-- Birthdate -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Doğum Tarihi"
                placeholder="Doğum Tarihi"
                v-bind:date-range="true"
                v-model="filter.birthdate"
              />
            </div>
            <!-- Birthdate -->

            <!-- Member Group -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="member_group"
                label="Üye Grubu"
                placeholder="Üye Grubu"
                v-bind:option-prop="memberGroups"
                v-model="filter.member_group"
              />
            </div>
            <!-- Member Group -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Current Code -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="current_code"
                label="Cari Hesap Kodu"
                placeholder="Cari Hesap Kodu"
                v-model="filter.current_code"
              />
            </div>
            <!-- Current Code -->

            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Üyelik Tarihi"
                placeholder="Üyelik Tarihi"
                v-bind:date-range="true"
                v-model="filter.created_at"
              />
            </div>
            <!-- Created At -->

            <!-- Durum -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="is_active"
                label="Durum"
                placeholder="Durum"
                v-bind:clearable="true"
                v-bind:option-prop="statusOptions"
                v-model="filter.is_active"
              />
            </div>
            <!-- Durum -->

            <!-- Phone -->
            <!-- LocalStorage'de bulunan filtreleme verisini phone içerisine atabilmek için WeInput yerine direkt WePhone kullanılmıştır. -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label class="custom-label">Cep Telefonu</label>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group mb-0">
                    <WePhone
                      placeholder="Cep Telefonu"
                      v-model="filter.phone"
                      class="input-group input-group-lg"
                      v-bind:wrapperClass="'phone-mask-wrapper-lib-full'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Phone -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <!-- ./Filter -->

    <WeCard class="mb-4" v-show="filter_active">
      <h5>E-Posta Gönderilecek Müşteri Listesi</h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:data="member.list"
        v-bind:allowSortIndex="false"
        v-bind:columns="columns"
        v-bind:ajax="true"
        v-bind:loading="loading"
        v-bind:table-result="tableResult"
        v-on:make-request="updateMemberList"
        v-bind:componentName="componentName"
        ref="memberTable"
      ></WeTable>
    </WeCard>

    <WeCard>
      <div class="row align-items-end">
        <!-- Template -->
        <div class="col">
          <label class="custom-label">E-Posta Listesi</label>
          <v-select
            placeholder="E-Posta Listesi"
            label="title"
            v-bind:options="emailListOptions"
            v-bind:multiple="true"
            v-model="form.emailList"
          />
        </div>
        <!-- ./Template -->

        <!-- Template -->
        <div class="col">
          <label class="custom-label">E-Posta Şablonu</label>
          <v-select
            placeholder="E-Posta Şablonu"
            label="title"
            v-bind:options="emailTemplateList"
            v-model="form.template"
          />
        </div>
        <!-- ./Template -->

        <!-- Title -->
        <div class="col">
          <WeInput
            class="mb-0"
            label="E-Posta Başlığı"
            v-model="form.title"
            v-bind:placeholder="form.template && form.template.title"
          />
        </div>
        <!-- ./Title -->

        <div class="col-auto">
          <div class="d-flex">
            <WeLanguageSwitcher v-model="form.lang" />
            <button class="btn btn-success" v-on:click="onSubmit">
              <template v-if="submit"
                ><i class="fas fa-spinner fa-spin"></i
              ></template>
              <template v-else>Gönder</template>
            </button>
          </div>
        </div>
      </div>
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "BulkEmail",
  data() {
    return {
      submit: false,
      loading: true,
      filter_active: false,
      componentName: "bulk-email-list",
      form: {
        title: null,
        template: null,
        lang: "tr",
        emailList: null,
      },
      columns: [
        { name: "full_name", th: "Müşteri Adı Soyadı", type: "string" },
        { name: "email", th: "E-Posta", type: "string" },
        { name: "current_code", th: "Cari Hesap Kodu", type: "string" },
        { name: "member_type", th: "Üye Tipi", type: "string" },
        { name: "member_group", th: "Üye Grubu", type: "string" },
        { name: "created_at", th: "Üyelik Tarihi", type: "datetime" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 0,
      },
      genders: [
        {
          id: "E",
          name: "Erkek",
        },
        {
          id: "K",
          name: "Kadın",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
      copyFilter: {},
      filter: {
        first_name: null,
        last_name: null,
        phone: null,
        gender: null,
        tcn: null,
        vkn: null,
        member_group: null,
        email: null,
        current_code: null,
        is_active: null,
        birthdate: null,
        created_at: null,
      },
    };
  },
  methods: {
    ...mapActions("member", ["getList"]),
    ...mapActions("emailTemplate", ["send"]),
    checkFilterStorage() {
      return helper.checkFilterStorage(this.componentName);
    },
    onSearch() {
      this.filter_active = true;
      this.copyFilter = helper.clone(this.filter);
      this.$refs.memberTable.currentPage = 1;
      helper.addCacheCurrentFilter(this.copyFilter, this.componentName);
      this.updateMemberList();
    },
    updateMemberList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      var localFilter = localStorage.getItem(
        `last_${this.componentName}_filter`
      );
      this.filter = helper.loadLocalFilter(this.filter, localFilter);
      this.copyFilter = helper.clone(this.filter);

      this.loading = true;
      this.getList({
        filter: { notification_email: 1, ...this.copyFilter },
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    onSubmit() {
      if (!this.submit) {
        if (!this.form.template_id) {
          this.$swal({
            title: "Hatalı İşlem",
            text: "Lütfen Şablon seçin",
            icon: "error",
          });
        }
        if (!this.form.template_id) {
          this.$swal({
            title: "Hatalı İşlem",
            text: "Lütfen Şablon seçin",
            icon: "error",
          });
        }
        if (this.getMembersCount > 0) {
          this.$swal({
            title: "İşlemi Onaylıyor musunuz ?",
            html: `<b>${this.getMembersCount}</b> müşteriye e-posta gönderilecek. Bu işlem geri alınamaz.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "İptal",
            confirmButtonText: "Gönder",
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.makeRequest();
            }
          });
        } else {
          this.$swal({
            title: "Geçersiz İşlem",
            text: this.form.emailList
              ? "E-Posta Listesinde müşteri bulunamadı"
              : "Lütfen E-Posta gönderilecek Müşterileri filtreleyin",
            icon: "error",
          });
        }
      }
    },
    makeRequest() {
      this.submit = true;
      this.send({
        form: {
          filter: this.copyFilter,
          title:
            this.form.title !== null
              ? this.form.title
              : this.form?.template?.title,
          template_id: this.form?.template?.id,
          email_lists: helper.arrayPluck(this.form?.emailList, "id"),
        },
        onSuccess: (result) => {
          this.$swal({
            title: "İşlem Başarılı",
            text: "E-Posta işlemi sıraya alındı. Gönderime başlanacak",
            icon: "success",
            confirmButtonText: "Tamam",
          });
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      member: (state) => state.member,
      memberGroups: (state) => state.membergroup.list,
      emailTemplateList: (state) => state.emailTemplate.list,
      emailListOptions: (state) => state.emailList.list,
    }),
    getMembersCount() {
      let result = 0;

      if (this.form.emailList) {
        this.form.emailList.forEach((list) => {
          result += list.members_count;
        });
      } else {
        result = this.tableResult.total_items;
      }

      return result;
    },
  },
  mounted() {
    this.$store.dispatch("membergroup/getList");
    this.$store.dispatch("emailTemplate/getList");
    this.$store.dispatch("emailList/getList");
  },
};
</script>